<template>
    <b-sidebar
      id="add-new-contract-sidebar"
      :visible="isAddNewContractSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-contract-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">Add New Contract</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <!-- BODY -->
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @submit.prevent="submitContract"
        >

          <!-- Agreement Reference -->
          <b-form-group label="Agreement Reference *" label-for="agreementRef">
            <b-form-input v-model="agreementRef" placeholder="Agreement Reference" required />
          </b-form-group>

          <!-- Customer -->
          <b-form-group label="Customer *" label-for="customer">
            <b-form-input v-model="customer" placeholder="Customer" required />
          </b-form-group>

          <!-- Description -->
          <b-form-group label="Description *" label-for="description">
            <b-form-input v-model="description" placeholder="Description" required />
          </b-form-group>

          <!-- Start Date -->
          <b-form-group label="Start Date *" label-for="startDate">
            <b-form-datepicker v-model="startDate" placeholder="Start Date" required />
          </b-form-group>

          <!-- End Date -->
          <b-form-group label="End Date *" label-for="endDate">
            <b-form-datepicker v-model="endDate" placeholder="End Date" required />
          </b-form-group>

          <!-- Attachments (Multiple) -->
          <b-form-group label="Attachments" label-for="attachments">
            <b-form-file v-model="attachments" multiple />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              block
              :disabled="submitButtonDisabled"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </template>
<script>
import {
    BFormGroup, BFormInput, BForm, BButton, BFormDatepicker, BFormFile, BSidebar,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"
import { getToken } from '@/auth/utils'
import { ref } from "@vue/composition-api"

const agreementRef = ref('')
const customer = ref('')
const description = ref('')
const issuedDate = ref(null)
const startDate = ref(null)
const endDate = ref(null)
const issuedBy = ref('')
const attachments = ref(null)
const submitButtonDisabled = ref(false)
export default {
  components: {
    BFormFile,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormDatepicker,
    BSidebar,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewcontractSidebarActive',
    event: 'update:is-add-new-contract-sidebar-active',
  },
  props: {
    isAddNewContractSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async submitContract() {
        const userToken = getToken()
      submitButtonDisabled.value = true
      const formData = new FormData()
      formData.append('AgreementRef', this.agreementRef)
      formData.append('Customer', this.customer)
      formData.append('Description', this.description)
      formData.append('StartDate', moment(this.startDate).format('YYYY-MM-DD'))
      formData.append('EndDate', moment(this.endDate).format('YYYY-MM-DD'))
      if (this.attachments) {
        for (let i = 0; i < this.attachments.length; i += 1) {
          formData.append('attachments[]', this.attachments[i])
        }
      }
      const headers = {
        'Content-Type': 'multipart/form-data',
        'X-Token-Access': `Bearer ${userToken}`,
      }
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/invoice_service/insertContract`,
          formData,
          { headers },
        )
        if (response.status === 200 && response.data.Status === 1) {
            this.$parent.getAllContracts()
            this.$parent.isAddNewContractSidebarActive = false
            this.agreementRef = ""
            this.customer = ""
            this.description = ""
            this.startDate = ""
            this.endDate = ""
            this.resetForm()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Contract Created Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to Create Contract',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error Submitting Contract',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        submitButtonDisabled.value = false
      }
    },
    resetForm() {
      this.agreementRef = ''
      this.customer = ''
      this.description = ''
      this.issuedDate = null
      this.startDate = null
      this.endDate = null
      this.issuedBy = ''
      this.attachments = null
    },
    data() {
        return {
      agreementRef,
      customer,
      description,
      issuedDate,
      startDate,
      endDate,
      issuedBy,
      attachments,
      submitButtonDisabled,
    }
  },
},
}
</script>
